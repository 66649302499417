.standard-create-btn{
	position: absolute;
	top: 51px;
	right: 20px;
	z-index: 999;
}

.page-title{
  padding: 10px 0px;
}

.navbar-inverse {
    color: rgba(255, 255, 255, 0.9) !important;
    background-color: #263238 !important;
}

select[multiple], select[size] {
    height: auto;
}
